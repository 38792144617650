"use client";

import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

export default function DownloadSection() {
  const pathName = usePathname();
  const t = useTranslations();
  //   const [data, setData] = useState(null);
  //   useEffect(() => {
  //     (async () => {
  //       const websiteInfo = await GetData({ endPoint: endpoints.websiteInfo });
  //       const socialMedia = websiteInfo?.data?.appLinks;
  //       setData(socialMedia);
  //     })();
  //   }, []);
  return (
    <>
      {pathName.length === 3 ? (
        <div className="w-full bg-Red flex justify-center mt-3 pt-[72px] flex-col">
          <h1 className="text-[18px] font-bold text-white text-center">
            {t("download_aroovia_app_for_apple_and_android")}
          </h1>
          <div className="flex justify-center pb-[62.5px]  gap-5 mt-5">
            <Link href={"#"}>
              <Image
                src={"/apple.svg"}
                alt="Apple Logo"
                width={146}
                height={46}
              />
            </Link>
            <Link
              href={
                "https://play.google.com/store/apps/details?id=com.ijd.aroovia"
              }
            >
              <Image
                src={"/googleplay.svg"}
                alt="Apple Logo"
                width={146}
                height={46}
              />
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
}
